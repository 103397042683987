import { Outlet } from 'react-router'

import { Footer } from '#app/components/footer.tsx'
import { Navbar } from '#app/components/navbar.tsx'

export default function MarketingLayout() {
	return (
		<div className="flex-grow flex-col overflow-auto md:flex">
			<Navbar />
			<Outlet />
			<Footer />
		</div>
	)
}
