export function Footer() {
	return (
		<h6 className="py-3 text-xs font-medium text-muted-foreground md:mx-12 md:mt-2">
			<div className="flex w-full items-center justify-center md:justify-between">
				<div className="hidden justify-between text-muted-foreground md:flex">
					<div>© 2025 Intermave Inc</div>
				</div>
				<div className="flex items-center justify-center gap-3">
					<a className="underline" href="/privacy">
						Privacy
					</a>
					<a className="underline" href="/terms">
						Terms
					</a>
					<a
						className="underline"
						rel="noopener noreferrer"
						href="mailto:support@intermave.com"
					>
						Contact
					</a>
				</div>
			</div>
			<div className="mb-1.5 mt-3 text-center text-muted-foreground md:hidden">
				© 2025 Intermave Inc
			</div>
		</h6>
	)
}
