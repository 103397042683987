import { Link } from 'react-router'

import { Button } from '#app/components/ui/button.tsx'
import { useOptionalUser } from '#app/utils/user.ts'
import { UserDropdown } from './user-dropdown'

export function Navbar() {
	const user = useOptionalUser()

	return (
		<header className="z-level-10 left-0 right-0 top-0">
			<div className="ml-4 mr-3 flex items-center justify-between py-4 md:mx-12">
				<div>
					<Link to="/">
						<img src="/img/logo.svg" className="h-8 w-8" />
					</Link>
				</div>
				<div className="flex items-center justify-end">
					<div className="flex flex-grow items-center justify-end space-x-2">
						<Link to="/explore">
							<Button variant="ghost">Explore</Button>
						</Link>
						{user ? (
							<UserDropdown />
						) : (
							<Link to="/login">
								<Button>
									Log in
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										stroke="currentColor"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
										className="lucide lucide-arrow-right ml-1 h-4 w-4"
									>
										<path d="M5 12h14"></path>
										<path d="m12 5 7 7-7 7"></path>
									</svg>
								</Button>
							</Link>
						)}
					</div>
				</div>
			</div>
		</header>
	)
}
